// Created By MK

const aServerDomain: any = {
  ISSO: "ISSO",
  WSFT: "WSFT",
  WSF: "WSF",
};
let appServerDomain: any = process.env.REACT_APP_SERVER_DOMAIN;
if (!appServerDomain || !aServerDomain.hasOwnProperty(appServerDomain)) {
  console.error("Invalid or missing app server platform. Defaulting to isso.");
  appServerDomain = "ISSO";
}

// wsf urls
const webPanel = (appServerDomain === "WSFT") ? "https://wsf.moshimoshi.tech" : "https://worldstemfederation.org";
const adminPanel = "https://admin.worldstemfederation.org";
const memberPanel = "https://member.worldstemfederation.org";
const membershipPanel = "https://membership.worldstemfederation.org";

const aPlatform: any = {
  ISSO: "ISSO",
  WSF: "WSF",
};
let appPlatform: any = process.env.REACT_APP_PLATFORM;
if (!appPlatform || !aPlatform.hasOwnProperty(appPlatform)) {
  console.error("Invalid or missing app platform. Defaulting to isso.");
  appPlatform = "ISSO";
}

// Fronend url
const frontendMode: any = {
  PRODUCTION: (appServerDomain === "WSF" ? "https://worldstemfederation.org" : (appServerDomain === "WSFT" ? "https://wsf.tjcg.in" : "https://isso.tjcg.in")),
  DEVELOPMENT: (appServerDomain === "WSF" ? "https://worldstemfederation.org" : (appServerDomain === "WSFT" ? "https://wsf.tjcg.in" : "https://isso.tjcg.in")),
  LOCAL: (appServerDomain === "WSF" ? "http://192.168.0.201:3000" : (appServerDomain === "WSFT" ? "http://192.168.0.201:3000" : "http://192.168.0.201:3000")),
};

// Bakcend Url
const mode: any = {
  PRODUCTION: (appServerDomain === "WSF" ? "https://worldstemfederation.org" : (appServerDomain === "WSFT" ? "https://wsf.tjcg.in" : "https://isso.tjcg.in")),
  DEVELOPMENT: (appServerDomain === "WSF" ? "https://worldstemfederation.org" : (appServerDomain === "WSFT" ? "https://wsf.tjcg.in" : "https://isso.tjcg.in")),
  LOCAL: (appServerDomain === "WSF" ? "http://192.168.0.137:7000" : (appServerDomain === "WSFT" ? "http://192.168.0.137:7000" : "http://192.168.0.137:7000")),
};

let appMode: any = process.env.REACT_APP_MODE;
if (!appMode || !mode.hasOwnProperty(appMode)) {
  console.error("Invalid or missing app mode. Defaulting to development.");
  appMode = "DEVELOPMENT";
}

const publicKey: any = (appServerDomain === "WSF" ? process.env.REACT_STRIPE_PUBLIC_KEY : (appServerDomain === "WSFT" ? process.env.REACT_STRIPE_PUBLIC_KEY : process.env.REACT_STRIPE_PUBLIC_KEY));


const UrlHelper = {
  frontendServerUrl: frontendMode[appMode],
  serverUrl: mode[appMode],
  serverApiUrl: mode[appMode] + "/api/",
  serverImageUrl: mode[appMode] + "/api/",
  serverDomain: appServerDomain,
  serverPlatForm: appPlatform,
  webServerUrl: webPanel,
  adminServerUrl: adminPanel,
  memberserverUrl: memberPanel,
  membershipserverUrl: membershipPanel,
  stripePublicKey: publicKey,
};

export default UrlHelper;
