// React Imports
import { useEffect } from "react";

// Third Party Imports
import { ToastContainer } from "react-toastify";

// Routes Imports
import RouterData from "./Routes";
import socket from './Config/Socket';
import UrlHelper from "./Helpers/Url";

const redirectedURL: any = {
  event: 'events-and-compititions',
  seminar: 'seminars',
  workshop: 'workshops',
  UserEvent: 'events-and-compititions',
  user_certificate: 'certificate-approvals'
};

const App = () => {

  const darkenColor = (color: string, percent: number) => {
    const num = parseInt(color.replace("#", ""), 16);
    const r = Math.max(0, (num >> 16) - Math.round((percent / 100) * 255));
    const g = Math.max(0, ((num >> 8) & 0x00ff) - Math.round((percent / 100) * 255));
    const b = Math.max(0, (num & 0x0000ff) - Math.round((percent / 100) * 255));
    // eslint-disable-next-line no-mixed-operators
    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
  }

  const updateRootVariables = (theme: any) => {
    const root = document.documentElement;
    if (theme === "WSF") {
      root.style.setProperty("--platform", "WSF");
      root.style.setProperty("--theme-default", "#255DAB");
      root.style.setProperty("--theme-default10", darkenColor("#255DAB", 10));
      root.style.setProperty("--theme-defaultrgb10", "rgba(37, 93, 171, 0.1)");
      root.style.setProperty("--theme-default33", "#255DAB33");
      root.style.setProperty("--theme-secondary", "#d5376f");
      root.style.setProperty("--theme-secondary10", darkenColor("#d5376f", 10));
      root.style.setProperty("--light-background", "rgba(242, 243, 247, 0.7)");
      root.style.setProperty("--body-font-color", "#86909C");
      root.style.setProperty("--chart-border", "#E6E9EB");
      root.style.setProperty("--recent-chart-bg", "#FCFCFD");
      root.style.setProperty("--light-bg", "#EEF1F6");
      root.style.setProperty("--white", "#fff");
      root.style.setProperty("--light2", "#F5F6F9");
      root.style.setProperty("--sidebar-border", "rgba(0, 0, 0, 0.1)");
      root.style.setProperty("--chart-text-color", "rgba(82, 82, 108, 0.8)");
      root.style.setProperty("--recent-dashed-border", "rgba(82, 82, 108, 0.3)");
      root.style.setProperty("--chart-dashed-border", "rgba(82, 82, 108, 0.2)");
      root.style.setProperty("--chart-progress-light", "rgba(82, 82, 108, 0.1)");
      root.style.setProperty("--recent-box-bg", "#F2F4F7");
      root.style.setProperty("--recent-border", "rgba(97, 101, 122, 0.25)");
      root.style.setProperty("--course-light-btn", "#F2F3F7");
      root.style.setProperty("--course-bg", "#F7F7F7");
      root.style.setProperty("--balance-profie-bg", "#E0DFEF");
      root.style.setProperty("--view-grid-bg", "#ECF3FA80");
      root.style.setProperty("--view-border-marker", "#cfcdfc");
      root.style.setProperty("--light-slate", "#F5F6F9");
      root.style.setProperty("--text-gray", "rgba(82, 82, 108, 0.8)");
      root.style.setProperty("--product-shadow", "0px 1px 0px rgba(82, 82, 108, 0.15)");
      root.style.setProperty("--bs-heading-color", "#051A1A");
      root.style.setProperty("--bs-card-color", "#051A1A");

          // rating 
      root.style.setProperty("--normal", "#ECEAF3");
      root.style.setProperty("--normal-shadow", "#D9D8E3");
      root.style.setProperty("--normal-mouth", "#9795A4");
      root.style.setProperty("--normal-eye", "#595861");
      root.style.setProperty("--active", "#F8DA69");
      root.style.setProperty("--active-shadow", "#F4B555");
      root.style.setProperty("--active-mouth", "#F05136");
      root.style.setProperty("--active-eye", "#313036");
      root.style.setProperty("--active-tear", "#76b5e7");
      root.style.setProperty("--active-shadow-angry", "#e94f1d");

       // gradient colors static
      root.style.setProperty("--gradient-card0", "rgba(60, 125, 205, 1)");
      root.style.setProperty("--gradient-card1", "rgba(37, 93, 171, 1)");
      root.style.setProperty("--gradient-card2", "rgba(15, 56, 103, 1)");
      root.style.setProperty("--gradient-landing0", "#3C79D9");
      root.style.setProperty("--gradient-landing1", "#1E4A91");
      root.style.setProperty("--primary-gradient-color", "#255DAB50");
      root.style.setProperty("--primary-gradient-color1", "#d5376f50");
    }
  };

  useEffect(() => {
    const platform = process.env.REACT_APP_PLATFORM; // Read from .env
    updateRootVariables(platform);
  }, []);

  useEffect(() => {
    // Check for Notification API support
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notifications')
      return;
    }

    // Request notification permission on component mount
    if (Notification.permission === 'default') {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          console.log('Notification permission denied')
        }
      });
    }
  }, []);

  useEffect(() => {
    const onNotificationArrived = (value: any) => {
      const title = value?.title ?? '';
      const options = {
        body: value?.message ?? '',
        icon: (UrlHelper.serverPlatForm === 'WSF') ? "./assets/images/wsf-favicon.png" : "./assets/images/favicon.png",
        tag: value?.reference_id,
        data: {
          url: value?.module === 'Inquiry' ? `${UrlHelper.frontendServerUrl}/inquiry/a/${value?.reference_id}` : `${UrlHelper.frontendServerUrl}/${redirectedURL[value?.module]}`
        }
      };

      if (Notification.permission === 'granted') {
        const notification = new Notification(title, options);
        notification.onclick = (event) => {
          event.preventDefault();
          window.open(notification.data.url, '_blank');
        };
      } else {
        console.error('Notification permission not granted');
      }
    };

    socket.on("notificationArrived", onNotificationArrived);
    
    // Cleanup Function
    return () => {
      socket.off("notificationArrived", onNotificationArrived);
    };
  }, []);

  useEffect(() => {
    let link = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement | null;
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (UrlHelper.serverPlatForm === 'WSF') {
      link.href = "./assets/images/wsf-favicon.png";
      document.title = "WSF";
    } else {
      link.href = "./assets/images/favicon.png";
      document.title = "ISSO";
    }

    
  }, []);


  return (
    <>
      <RouterData />
      <ToastContainer />
    </>
  );
}

export default App;
